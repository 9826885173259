@font-face {
  font-family: "VideoJS";
  src: url("./assets/fonts/vjs.eot");
  src: url("./assets/fonts/vjs.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/vjs.woff") format("woff"),
    url("./assets/fonts/vjs.ttf") format("truetype");
}

.video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before,
.vjs-icon-pause:before {
  content: "\f103";
  font-family: "VideoJS";
}

.video-js .vjs-mute-control .vjs-icon-placeholder:before,
.vjs-icon-volume-high:before {
  content: "\f107";
  font-family: "VideoJS";
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before,
.video-js .vjs-play-control .vjs-icon-placeholder:before,
.vjs-icon-play:before {
  content: "\f101";
  font-family: "VideoJS";
}

.video-js .vjs-picture-in-picture-control .vjs-icon-placeholder:before,
.vjs-icon-picture-in-picture-enter:before {
  content: "\f121";
  font-family: "VideoJS";
}

.video-js .vjs-fullscreen-control .vjs-icon-placeholder:before,
.vjs-icon-fullscreen-enter:before {
  content: "\f108";
  font-family: "VideoJS";
}
